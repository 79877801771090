/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

export const conversion = (sendTo: string, props?: any, eventType = 'conversion') => {
  try {
    if (typeof window.gtag === 'function') {
      window.gtag('event', eventType, {
        allow_custom_scripts: true,
        ...props,
        send_to: sendTo,
      });
    }
  } catch (error) {
    console.error('ga.ts | conversion', error);
  }
};
