import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Flex from '../../components/Common/Flex';
import MarketingBanner from '../../components/Common/MarketingBanner';
import { AppConfig } from '../../constants';
import { useIsLargerScreen } from '../../hooks';
import { useAppImages } from '../../hooks/useAppImages';

const createStyle = ({ theme, isLargerScreen }: { theme: Theme; isLargerScreen: boolean }) => ({
  container: {
    width: '100vw',
    flexDirection: 'column',
    alignItems: 'center',
    paddingX: !isLargerScreen ? 0 : '10vh',
    marginTop: !isLargerScreen ? 20 : '10vh',
  },
  content: {
    width: isLargerScreen ? 240 : 144,
    height: isLargerScreen ? 83 : 50,
  },
  title: {
    ...theme.typography.h4,
    fontSize: isLargerScreen ? 36 : '3.5vh',
    textAlign: 'center',
    marginTop: isLargerScreen ? 90 : '8vh',
  } as CSSProperties,
  description: {
    ...theme.typography.h4,
    fontSize: isLargerScreen ? 46 : '3.5vh',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  } as CSSProperties,
  shortDescription: {
    ...theme.typography.h6,
    fontSize: 14,
    textAlign: 'center',
    marginTop: isLargerScreen ? 30 : '10vh',
  } as CSSProperties,
});

const EnhancedBanner = () => {
  const theme = useTheme();
  const { splashLogo } = useAppImages();
  const isLargerScreen = useIsLargerScreen();
  const styles = createStyle({ theme, isLargerScreen });

  return (
    <Flex
      width={'100vw'}
      flexDirection="column"
      alignItems="center"
      paddingX={isLargerScreen ? 0 : '9vh'}
      marginTop={isLargerScreen ? 20 : '10vh'}
    >
      <Box
        data-testid="loading-app-name"
        component="img"
        src={splashLogo}
        alt={AppConfig.APP_NAME}
        sx={styles.content}
      />

      <p data-testid="header-label" style={styles.title}>
        {AppConfig.SPLASH_TITLE_TEXT}
      </p>

      <div data-testid="header-label" style={styles.description}>
        {AppConfig.SPLASH_DESCRIPTION_TEXT?.split('\\n').join(isLargerScreen ? '\n' : ' ')}
      </div>

      <p data-testid="header-label" style={styles.shortDescription}>
        {AppConfig.SPLASH_SHORT_DESCRIPTION_TEXT}
      </p>
    </Flex>
  );
};

export const Loading = () => {
  const location = useLocation();
  const isLargerScreen = useIsLargerScreen();
  const { logo, spinner } = useAppImages();
  const showEnhancedBanner =
    AppConfig.PURCHASE_FLOW_DISABLED ||
    (AppConfig.SPLASH_ENHANCED?.toLowerCase() === 'true' && location.search.includes('wcc'));

  return (
    <Flex
      width="100vw"
      height={isLargerScreen ? 733 : '90vh'}
      justifyContent={showEnhancedBanner ? 'none' : 'center'}
      alignItems="center"
      bgcolor="common.white"
      flexDirection="column"
    >
      {isLargerScreen && <MarketingBanner style={{ position: 'absolute', top: 0 }} />}

      {showEnhancedBanner ? (
        <EnhancedBanner />
      ) : (
        <Flex flexDirection="column" alignItems="center" alignSelf={'center'}>
          <Box data-testid="loading-app-name" component="img" src={logo} alt={AppConfig.APP_NAME} />
          <Box data-testid="loading-spinner" component="img" src={spinner} width={64} height={64} />
        </Flex>
      )}
    </Flex>
  );
};
