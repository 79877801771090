import { PropsWithChildren, createContext, useEffect } from 'react';
import { AppConfig, GA_EVENTS, SCREEN_IDS } from '../constants';
import { RootState, useAppSelector } from '../store';
import { conversion as gaConversion } from '../utils/ga';

type ContextValuesProps = {
  conversion?: (sendTo: string, props?: any, eventType?: string) => void;
};

const SCREEN_EVENT_MAPPING = [
  { screen: SCREEN_IDS.selectAmountScreen, event: GA_EVENTS.SELECT_AMOUNT },
  { screen: SCREEN_IDS.paymentCardScreen, event: GA_EVENTS.PAYMENT_CARD },
  { screen: SCREEN_IDS.recipientsDetailScreen, event: GA_EVENTS.RECIPIENT_DETAILS },
];

export const GaEventsContext = createContext<ContextValuesProps>({});

const GaEventsContextProvider = ({ children }: PropsWithChildren) => {
  const { screen } = useAppSelector((state: RootState) => state.screen.screenStates);

  const conversion = (sendTo?: string, props?: unknown, eventType?: string) => {
    if (!sendTo) return;
    gaConversion(`${AppConfig.GA_ID}/${sendTo}`, props, eventType);
  };

  useEffect(() => {
    if (!screen) return;
    const mapping = SCREEN_EVENT_MAPPING.find((s) => s.screen === screen);
    if (!mapping) return;
    conversion(mapping.event);
  }, [screen]);

  return (
    <GaEventsContext.Provider
      value={{
        conversion,
      }}
    >
      {children}
    </GaEventsContext.Provider>
  );
};

export default GaEventsContextProvider;
