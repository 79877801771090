import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getFeesAndGst } from '../../api/AppClip/transaction';
import App from '../../App';
import { Container } from '../../components/AppClip';
import Flex from '../../components/Common/Flex';
import { AppConfig, FEES_GST_FAILURE_RETRY_LIMIT, GA_EVENTS, SCREEN_IDS, TENANTS } from '../../constants';
import { GaEventsContext } from '../../contexts/GaEventsContext';
import { useIsLargerScreen } from '../../hooks/useIsMobileView';
import {
  RootState,
  customiseGiftActions,
  merchantActions,
  paymentActions,
  purchaseActions,
  recipientActions,
  screenActions,
  tenantActions,
  useAppDispatch,
  useAppSelector,
  scheduledDeliveryActions,
} from '../../store';
import AppClipWeb from './index';
import { Loading } from './Loading';

const MobileBody = ({ children }: PropsWithChildren) => {
  return (
    <Flex alignItems={'center'} alignSelf={'center'} flexDirection={'column'} width={{ xs: '65%', sm: '70%' }}>
      <Flex flexDirection={'column'}>{children}</Flex>
    </Flex>
  );
};

const DesktopBody = ({ children }: PropsWithChildren) => {
  return (
    <Flex alignItems={'center'} alignSelf={'center'} flexDirection={'column'} width={'65%'} flexGrow={1} height="100%">
      <Flex flexDirection={'column'} justifyContent={'center'} flexGrow={1} height="100%">
        {children}
      </Flex>
    </Flex>
  );
};

const Home = () => {
  const theme = useTheme();
  const isDesktop = useIsLargerScreen();
  const dispatch = useAppDispatch();
  const [feesError, setFeesError] = useState(false);
  const { merchantData: merchantDataState } = useAppSelector((state: RootState) => state.merchant);
  const { loading } = useAppSelector((state: RootState) => state.purchase);
  const { selectAmountScreen } = SCREEN_IDS;
  const [proceedToNext, setProceedToNext] = useState(false);
  const merchantName: string | undefined = AppConfig.DEFAULT_MERCHANT_NAME;
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const location = useLocation();
  const { conversion } = useContext(GaEventsContext);

  useEffect(() => {
    // qrcode entry
    if (location.search.includes('wcc') && GA_EVENTS?.QR_ENTRY && conversion) {
      conversion(GA_EVENTS.QR_ENTRY);
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const tenant = TENANTS[AppConfig.DEFAULT_MERCHANT_NAME as keyof typeof TENANTS];
    dispatch(tenantActions.setTenantDetails(tenant));
    fetchFeeData();

    if (!AppConfig.PURCHASE_FLOW_DISABLED) {
      setTimeout(() => {
        setProceedToNext(true);
      }, Number(AppConfig.SPLASH_LOADING_MS ?? 2000));
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  const queryFailureRetryCount = useRef(0);
  const { selectedAmount, fees } = useAppSelector((state: RootState) => state.purchase);

  const fetchFeeData = useCallback(async () => {
    try {
      dispatch(purchaseActions.setLoading(true));
      const data = await getFeesAndGst(AppConfig.PARTNER, AppConfig.PROGRAM, AppConfig.APP_ID);
      const { feesValue = 0, gstValue = 0 } = data ?? {}; // Destructure feesValue and gstValue from data
      const fee = feesValue / 100;
      const gst = gstValue / 100;
      batch(() => {
        dispatch(purchaseActions.setFees(fee + gst));
        dispatch(purchaseActions.setGst(gst));
      });
    } catch (error) {
      if (queryFailureRetryCount.current < FEES_GST_FAILURE_RETRY_LIMIT) {
        queryFailureRetryCount.current += 1;
        fetchFeeData();
      } else {
        setFeesError(true);
        dispatch(purchaseActions.setFees(0));
        dispatch(purchaseActions.setGst(0));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(purchaseActions.setTotalAmount(selectedAmount + fees));
  }, [selectedAmount, fees]);

  useEffect(() => {
    const resetStates = () => {
      batch(() => {
        dispatch(recipientActions.resetRecipient());
        dispatch(purchaseActions.setSelectedAmount(50));
        dispatch(paymentActions.resetPayment());
        dispatch(customiseGiftActions.resetAnimation());
        dispatch(purchaseActions.resetUUID4());
        dispatch(merchantActions.reset());
        dispatch(scheduledDeliveryActions.reset());
        dispatch(
          screenActions.setScreen({
            screen: selectAmountScreen,
            loading: false,
            updating: false,
            isCreditCard: 0,
            isExpansion: false,
          }),
        );
      });
    };

    if (merchantDataState.merchantName !== merchantName) {
      resetStates();
    }
  }, [merchantName, dispatch, merchantDataState.merchantName, selectAmountScreen]);

  const renderContent = () => {
    if (isFirstLoad && !AppConfig.PURCHASE_FLOW_DISABLED) {
      setIsFirstLoad(true);
      return null;
    }
    return <Loading />;
  };

  if (proceedToNext) {
    return <AppClipWeb loading={loading} />;
  }

  const ContainerComponent = isDesktop ? Box : Container;

  return (
    <>
      <Helmet>
        <title>{AppConfig.PAGE_TITLE}</title>
        <meta name="description" content={AppConfig.PAGE_TITLE} />
      </Helmet>
      <ContainerComponent
        sx={{
          width: '100%',
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Flex flexGrow={1} flexDirection={'column'} justifyContent={'space-between'} position={'relative'} width="100%">
          {isDesktop ? <DesktopBody>{renderContent()}</DesktopBody> : <MobileBody>{renderContent()}</MobileBody>}
        </Flex>
      </ContainerComponent>
    </>
  );
};

export default Home;
